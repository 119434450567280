import React, { useEffect } from "react";
import './Sedes.css'

function Sedes() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="divSedes">
            <div className="titleSedes" data-aos="fade-up" data-aos-duration="1000">Nuestras sedes</div>
            <div className="rayaSedes"></div>

            <div className="contSedes">
                <div className="sede">
                    <div className="titleSede" data-aos="fade-right" data-aos-duration="3000">Sede Buenaventura</div>
                    <div className="fotoCont">
                        <div className="fotoSede"></div>
                        <div className="dirSede">Cra 5ta A No. 2 – 32 Oficina 401 <br></br> Centro - Buenaventura. <br></br> +57 3135559483 / 601 755 1866 </div>
                    </div>
                </div>
                <div className="sede">
                    <div className="titleSede" data-aos="fade-right" data-aos-duration="3000">Sede Bogotá</div>
                    <div className="fotoCont">
                        <div className="fotoSede2"></div>
                        <div className="dirSede">Calle 26 # 96J - 66 <br></br> Edificio Optimus Oficina 214 - Bogotá. <br></br> +57 3506246884 / 601 755 1866 </div>
                    </div>
                </div>
                <div className="sede">
                    <div className="titleSede" data-aos="fade-right" data-aos-duration="3000">Sede Cartagena</div>
                    <div className="fotoCont">
                        <div className="fotoSede3"></div>
                        <div className="dirSede">Calle 32B 10B – 25 Oficina 411 <br></br> Edificio Lequerica - Cartagena. <br></br>+57 3183697094 / 601 755 1866</div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export { Sedes };